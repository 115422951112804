import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5c04b34a07f986c19f875674de8db66f/6bf9f/participants.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd6LAD//xAAWEAADAAAAAAAAAAAAAAAAAAAAESD/2gAIAQEAAQUCHP8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITEg/9oACAEBAAE/IVSUrHXlw54//9oADAMBAAIAAwAAABBkz//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxARf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAgEFAAAAAAAAAAAAAAERACFBEDFRYcH/2gAIAQEAAT8QJuk5vB9wgSQoUuey5C2bxDvN+en/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "participants",
            "title": "participants",
            "src": "/static/5c04b34a07f986c19f875674de8db66f/e5166/participants.jpg",
            "srcSet": ["/static/5c04b34a07f986c19f875674de8db66f/f93b5/participants.jpg 300w", "/static/5c04b34a07f986c19f875674de8db66f/b4294/participants.jpg 600w", "/static/5c04b34a07f986c19f875674de8db66f/e5166/participants.jpg 1200w", "/static/5c04b34a07f986c19f875674de8db66f/d9c39/participants.jpg 1800w", "/static/5c04b34a07f986c19f875674de8db66f/6bf9f/participants.jpg 2225w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Uniswap ecosystem is primarily comprised of three types of users: liquidity providers, traders, and developers. Liquidity providers are incentivized to contribute `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-20"
      }}>{`ERC-20`}</a>{` tokens to common liquidity pools. Traders can swap these tokens for one another for a fixed `}<Link to="/docs/honeyswap/advanced-topics/fees" mdxType="Link">{`0.30% fee`}</Link>{` (which goes to liquidity providers). Developers can integrate directly with Uniswap smart contracts to power new and exciting interactions with tokens, trading interfaces, retail experiences, and more.`}</p>
    <p>{`In total, interactions between these classes create a positive feedback loop, fueling digital economies by defining a common language through which tokens can be pooled, traded and used.`}</p>
    <h1 {...{
      "id": "liquidity-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#liquidity-providers",
        "aria-label": "liquidity providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Providers`}</h1>
    <p>{`Liquidity providers, or LPs, are not a homogenous group:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Passive LPs are token holders who wish to passively invest their assets to accumulate trading fees.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Professional LPs are focused on market making as their primary strategy. They usually develop custom tools and ways of tracking their liquidity positions across different DeFi projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Token projects sometimes choose to become LPs to create a liquid marketplace for their token. This allows tokens to be bought and sold more easily, and unlocks interopability with other DeFi projects through Uniswap.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Finally, some DeFi pioneers are exploring complex liquidity provision interactions like incentivized liquidity, liquidity as collateral, and other experimental strategies. Uniswap is the perfect protocol for projects to experiment with these kinds of ideas.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "traders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#traders",
        "aria-label": "traders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Traders`}</h1>
    <p>{`There are a several categories of traders in the protocol ecosystem:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Speculators use a variety of community built tools and products to swap tokens using liquidity pulled from the Uniswap protocol.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Arbitrage bots seek profits by comparing prices across different platforms to find an edge. (Though it might seem extractive, these bots actually help equalize prices across broader Ethereum markets and keep things fair.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DAPP users buy tokens on Uniswap for use in other applications on Ethereum.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Smart contracts that execute trades on the protocol by implementing swap functionality (from products like DEX aggregators to custom Solidity scripts).`}</p>
      </li>
    </ul>
    <p>{`In all cases, trades are subject to the same flat fee for trading on the protocol. Each is important for increasing the accuracy of prices and incentivizing liquidity.`}</p>
    <h1 {...{
      "id": "developersprojects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developersprojects",
        "aria-label": "developersprojects permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developers/Projects`}</h1>
    <p>{`There are far too many ways Uniswap is used in the wider Ethereum ecosystem to count, but some examples include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The open-source, accessible nature of Uniswap means there are countless UX experiments and front-ends built to offer access to Uniswap functionality. You can find Uniswap functions in most of the major DeFi dashboard projects. There are also many `}<a parentName="p" {...{
            "href": "https://github.com/Uniswap/universe"
          }}>{`Uniswap-specific tools`}</a>{` built by the community.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wallets often integrate swapping and liquidity provision functionality as a core offering of their product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DEX (decentralized exchange) aggregators pull liquidity from many liquidity protocols to offer traders the best prices but splitting their trades. Uniswap is the biggest single decentralized liquidity source for these projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Smart contract developers use the suite of functions available to invent new DeFi tools and other various experimental ideas. See projects like `}<a parentName="p" {...{
            "href": "https://unisocks.exchange/"
          }}>{`Unisocks`}</a>{` or `}<a parentName="p" {...{
            "href": "https://ourzora.com/"
          }}>{`Zora`}</a>{`, among many, many others.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "uniswap-team-and-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#uniswap-team-and-community",
        "aria-label": "uniswap team and community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uniswap Team and Community`}</h1>
    <p>{`The Uniswap team along with the broader Uniswap community drives development of the protocol and ecosystem.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      